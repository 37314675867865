import React from 'react'
import { Formik, Field } from 'formik'
import { navigate } from 'gatsby-link'
import validationSchema from './validationSchema'

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

const ContactForm = () => {
  return (
    <Formik
      initialValues={{ name: '', email: '', message: '' }}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        fetch('/?no-cache=1', {
          //eslint-disable-line
          method: 'POST',
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          body: encode({
            'form-name': 'contact',
            ...values,
          }),
        })
          .then(() => {
            navigate('/')
            setSubmitting(false)
          })
          .catch(error => {
            console.log(error)
            alert("Error: Please Try Again!"); //eslint-disable-line
            setSubmitting(false)
          })
      }}
      render={({
        errors,
        touched,
        isSubmitting,
        handleSubmit,
        handleReset,
      }) => (
        <form
          name='contact'
          onSubmit={handleSubmit}
          onReset={handleReset}
          data-netlify='true'
          data-netlify-honeypot='bot-field'
        >
          <div className='relative border rounded mb-4 shadow appearance-none label-floating'>
            <div>
              <Field
                className='w-full py-2 px-3 text-gray-700 leading-normal rounded'
                type='text'
                placeholder='Full Name'
                name='name'
                id='name'
              />
            </div>
            {touched.name && errors.name && (
              <small className='has-text-danger'>{errors.name}</small>
            )}
          </div>

          <div className='relative border rounded mb-4 shadow appearance-none label-floating'>
            <div>
              <Field
                className='w-full py-2 px-3 text-gray-700 leading-normal rounded'
                type='email'
                placeholder='Email'
                name='email'
                id='email'
              />
            </div>
            {touched.email && errors.email && (
              <small className='has-text-danger'>{errors.email}</small>
            )}
          </div>

          <div className='relative border rounded mb-4 shadow appearance-none label-floating'>
            <div>
              <Field
                className='w-full py-2 px-3 text-gray-700 leading-normal rounded'
                component='textarea'
                placeholder='Message'
                name='message'
                id='message'
                rows='6'
              />
            </div>
            {touched.message && errors.message && (
              <small className='has-text-danger'>{errors.message}</small>
            )}
          </div>
          <div className='flex'>
            <div>
              <button className='bg-transparent hover:bg-black text-black text-xl font-primary hover:text-white py-2 px-4 border border-black hover:border-transparent m-1' type='reset'>
                Clear
              </button>
            </div>
            <div>
              <button
                className='button is-primary bg-primary hover:bg-black text-white text-xl font-primary py-2 px-4 border border-transparent hover:border-black m-1'
                type='submit'
                disabled={isSubmitting}
              >
                Submit
              </button>
            </div>
          </div>
        </form>
      )}
    />
  )
}

export { ContactForm }
