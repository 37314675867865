import React, { Component } from 'react'
import NavBar from '../NavBar'
import Footer from '../Footer'

class Page extends Component {
  constructor (props) {
    super(props)
    this.state = { isActive: false }
    this.toggleNavbar = this.toggleNavbar.bind(this)
  }

  toggleNavbar () {
    this.setState({ isActive: !this.state.isActive })
  }

  render () {
    return (
      <>
        <NavBar
          isActive={this.state.isActive}
          isHome={this.props.isHome}
          toggleNavbar={() => this.toggleNavbar()}
        />
        {this.props.children}
        <Footer />
      </>
    )
  }
}

export default Page
