import React from 'react'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'
import Page from '../Page'
import { ContactForm } from '../forms'

const ContactPageTemplate = ({
  title,
  subtitle,
  meta_title,
  meta_description,
}) => {
  return (
    <Page>
      <Helmet>
        <title>{meta_title}</title>
        <meta name='description' content={meta_description} />
      </Helmet>
      <section className='font-sans hero-contact'>
        <div className='container m-auto flex flex-col md:flex-row max-w-6xl'>
          <div className='flex flex-col w-full lg:w-1/2 justify-center items-start text-white py-8'>
            <span className='my-4 text-4xl font-thin font-secondary uppercase'>
              {title}
            </span>
            <p className='leading-normal mb-4 font-primary text-5xl'>{subtitle}</p>
          </div>
          <div className='w-full lg:w-1/4 lg:py-6' />
          <div className='w-full lg:w-1/4 lg:py-6'>
            <img
              className='w-full'
              alt='Book Cover'
              src='/img/book.jpg'
            />
          </div>
        </div>
      </section>
      <section className='section'>
        <div className='font-sans text-sm rounded w-full mx-auto my-8 px-8 pt-6 pb-8'>
          <ContactForm />
        </div>
      </section>
    </Page>
  )
}

ContactPageTemplate.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  meta_title: PropTypes.string,
  meta_description: PropTypes.string,
}

export default ContactPageTemplate
