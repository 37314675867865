import React from 'react'
import { Link, graphql, StaticQuery } from 'gatsby'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import SearchBox from '../SearchBox'

const NavBar = ({ toggleNavbar, isActive, isHome }) => (
  <StaticQuery
    query={graphql`
            query SearchIndexQuery {
                siteSearchIndex {
                    index
                }
            }
        `}
    render={data => (
      <header className='bg-gray-900 sm:flex sm:justify-between sm:items-center sm:px-4 sm:py-3 fixed w-full'>
        <div className='flex items-center justify-between px-4 py-3 sm:p-0'>
          <div>
            <Link to='/' className='navbar-item'>
              <strong className='text-2xl md:text-xl lg:text-2xl xl:text-4xl font-light text-white font-primary'>The Godfathers of Sex Abuse</strong>
            </Link>
          </div>
          <div className='sm:hidden'>
            <button onClick={toggleNavbar} type='button' className='block text-gray-500 hover:text-white focus:text-white focus:outline-none'>
              <svg className='h-6 w-6 fill-current' viewBox='0 0 24 24'>
                {isActive ? <path fillRule='evenodd' d='M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z' /> : <path fillRule='evenodd' d='M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z' />}
              </svg>
            </button>
          </div>
        </div>
        <nav className={`${isActive ? 'block' : 'hidden'} px-2 pt-2 pb-4 sm:flex sm:p-0 font-sans`}>
          {isHome ? <><AnchorLink className='block px-2 py-1 text-white text-xl md:text-md lg:text-lg xl:text-xl font-light rounded hover:bg-gray-800' offset={() => 100} href='#praise'>Book Reviews</AnchorLink>
            <AnchorLink className='block px-2 py-1 text-white text-xl md:text-md lg:text-lg xl:text-xl font-light rounded hover:bg-gray-800' offset={() => 100} href='#series'>About The Series</AnchorLink>
            <AnchorLink className='block px-2 py-1 text-white text-xl md:text-md lg:text-lg xl:text-xl font-light rounded hover:bg-gray-800' offset={() => 100} href='#author'>About Deana</AnchorLink>
            <AnchorLink className='block px-2 py-1 text-white text-xl md:text-md lg:text-lg xl:text-xl font-light rounded hover:bg-gray-800' offset={() => 100} href='#other'>Other Projects</AnchorLink>
          </> : <></>}
          <Link to='/blog' className='mt-1 block px-2 py-1 text-white text-xl md:text-md lg:text-lg xl:text-xl font-light rounded hover:bg-gray-800 sm:mt-0 sm:ml-2'>Blog</Link>
          <Link to='/contact' className='block px-2 py-1 text-white text-xl md:text-md lg:text-lg xl:text-xl font-light rounded hover:bg-gray-800'>Contact</Link>
          <SearchBox searchIndex={data.siteSearchIndex.index} />
        </nav>
      </header>
    )}
  />
)

export default NavBar
