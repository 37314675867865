import React, { Component } from 'react'
import { Link } from 'gatsby'
import { Index } from 'elasticlunr'

export default class SearchBox extends Component {
  constructor (props) {
    super(props)
    this.state = {
      query: ``,
      results: [],
      isActive: false,
    }
  }

  render () {
    return (
      <div className='relative font-sans'>
        <input
          className='relative z-10 block w-full focus:outline-0 bg-white py-2 px-6 mb-0 sm:mb-0'
          type='text'
          value={this.state.query}
          onChange={this.search}
          placeholder='Search'
          label='Global Search Bar'
          aria-label='Global Search Bar'
        />
        {this.state.isActive ? (
          <>
            <button
              tabIndex='-1'
              className='fixed inset-0 h-full w-full bg-black opacity-50 cursor-default'
              onClick={this.cancelSearch}
            />
            <div className='absolute mt-2 py-2 w-48 bg-white rounded-lg shadow-xl'>
              {this.state.results.map(page => (
                <Link
                  className='block px-4 py-2 text-gray-800 hover:bg-indigo-500 hover:text-white'
                  key={page.id}
                  to={page.slug}
                >
                  {page.title}
                </Link>
              ))}
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
    )
  }

  getOrCreateIndex = () =>
    this.index ? this.index : Index.load(this.props.searchIndex);

  search = evt => {
    const query = evt.target.value
    this.index = this.getOrCreateIndex()
    this.setState({
      query,
      // Query the index with search string to get an [] of IDs
      results: this.index
        .search(query, { expand: true }) // Accept partial matches
        // Map over each ID and return the full document
        .map(({ ref }) => this.index.documentStore.getDoc(ref)),
      isActive: !!query,
    })
  };

  cancelSearch = () => {
    this.setState({ isActive: false })
  };
}
