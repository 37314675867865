import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faTwitter } from '@fortawesome/free-brands-svg-icons'
import config from '../../../config'

const Footer = () => {
  return (
    <footer className='font-sans bg-black text-white py-8 px-4'>
      <div className='mx-auto container overflow-hidden flex flex-col md:flex-row justify-between pb-4 text-center md:text-left'>
        <a href='#' className='block mr-4 w-full md:w-1/4'>
          <span className='font-primary text-3xl'>
            The Godfathers of Sex Abuse
          </span>
        </a>
        <div className='w-full text-gray-300 mt-6 md:mt-0 leading-normal'>
          <h4 className='py-2 uppercase font-bold tracking-wide'>About US</h4>
          <p>
            The Godfathers of Sex Abuse books detail the lives and ways of the
            most notorious #MeToo era sexual predators, Jeffrey Epstein, Harvey
            Weinstein, and Bill Cosby. Author and law professor Deana Pollard
            Sacks offers the most comprehensive look ever at these predators
            with an emphasis on their enablers and their master manipulation of
            the legal system and the press. After perusing court files,
            deposition transcripts, and hundreds of news reports, Professor
            Sacks synthesizes the material into an easy-to-read book that paints
            a full picture of the life and crimes of these twisted sexual
            perverts.
          </p>
        </div>
        <div className='text-gray-700 flex flex-col items-center w-full md:w-1/4 mt-6 md:mt-0'>
          <h4 className='inline-block py-2 px-3 uppercase font-bold tracking-wide text-gray-300'>
            Follow Us
          </h4>
          <div className='flex pl-0 md:pl-4 justify-start'>
            <a
              className='block flex items-center text-gray-300 hover:text-white mr-6 no-underline'
              href='https://www.facebook.com/MeetTheProfessors'
            >
              <FontAwesomeIcon icon={faFacebook} />
            </a>
            <a
              className='block flex items-center text-gray-300 hover:text-white mr-6 no-underline'
              href='https://twitter.com/DeanaSacks'
            >
              <FontAwesomeIcon icon={faTwitter} />
            </a>
          </div>
        </div>
      </div>
      <div className='pt-4 mt-4 text-gray-700 text-xs border-t border-gray-300 text-center'>
        {' '}
        {config.copyright}{' '}
      </div>
    </footer>
  )
}

export default Footer
